import { useState } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { Service } from './Service'

const CreateFeature = () => {
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const onFinish = async (values: any) => {
        setSubmitting(true);

        let feature = {
            name: values.name,
            key: values.key,
            variants: [
                { value: true }
            ]
        }

        try {            
            const response = await Service.createFeature(feature);
            response.ok ? setSuccess(true) : setError(true);
        } catch {
            setError(true);
        } finally {
            setSubmitting(false);
        }        

    }

    return (
        <Form
        name="create"
        layout="horizontal"
        form={form}
        labelCol={{ span: 4 }} 
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
        >
            {success ? 
                <Alert
                message="Feature created!"
                type="success"
                showIcon
                closable
                style={{ marginBottom: '1.5rem' }}
                />
            : <></> }
            
            {error ? 
                <Alert
                message="There was a problem."
                type="error"
                showIcon
                closable
                style={{ marginBottom: '1.5rem' }}
                />
            : <></> }

            <Form.Item label="Name" name="name"
                rules={[
                    {
                      required: true,
                      message: 'Your feature needs a name.',
                    },
                  ]}
            >
                <Input placeholder="e.g. Shopping Cart" />
            </Form.Item>
            <Form.Item label="Key" name="key"
                rules={[
                    {
                      required: true,
                      message: 'Your feature needs a key.',
                    },
                    {
                        validator: (_, value) => 
                            /^[a-z0-9]+[-]*[a-z0-9]+$/g.test(value) 
                                ? Promise.resolve() 
                                : Promise.reject(new Error('Feature keys can consist only of lowercase letters, numbers, and hyphens (-)'))
                    }
                  ]}
            >
                <Input placeholder="e.g. shopping-cart" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" loading={submitting} htmlType="submit">{submitting ? 'Creating...' : 'Create'}</Button>
            </Form.Item>
        </Form>           
    )
}

export default CreateFeature;