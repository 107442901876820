import 'antd/dist/antd.css'
import './App.css';
import { Layout, Button, Drawer } from 'antd';
import FeatureList from './features/FeatureList';
import CreateFeature from './features/CreateFeature';
import { useState, useEffect } from 'react';
import { featureEnabled } from './SDK/Cloudflag'

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const { Header, Content } = Layout;

// Configure Firebase.
const firebaseConfig = {
  apiKey: "AIzaSyBFjAsJg7sqhdeLDDY4w_PX-eSPuHN_vDI",
  authDomain: "cloudflag-3f7b2.firebaseapp.com",
  projectId: "cloudflag-3f7b2",
  storageBucket: "cloudflag-3f7b2.appspot.com",
  messagingSenderId: "487216410173",
  appId: "1:487216410173:web:f1340a02034c3e7f38c454"
};

firebase.initializeApp(firebaseConfig);

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  disableSignUp: {
    status: false
  },
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

function App() {

  const [visible, setVisible] = useState(false);
  const [create, setCreate] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  useEffect(() => {
    async function checkFeatureEnabled(){
      if(!create && await featureEnabled('create-feature', false)){
        setCreate(true);
      }
    }
    
    checkFeatureEnabled();

  }, [create]);

  const onClose = () => {
    setVisible(false);
  };

  if (!isSignedIn) {
    return (
      <Layout>
        <Layout>
          <Header>
           <h1>Cloudflag</h1>
          </Header>
          <Content>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
          </Content>
        </Layout>
      </Layout>  
    );
  } else {
    return (
      <Layout>
        <Layout>
        <Header style={{ display: 'flex', justifyContent: 'space-between' }}>
           <h1>Cloudflag</h1>
           <a onClick={() => firebase.auth().signOut()}>Sign-out</a>
          </Header>
          <Content>
            {create 
              ? <div style={{ padding: '1.5rem', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="primary" onClick={() => setVisible(!visible)}>Create feature</Button>
                </div>
              : <></>
            }          
            <FeatureList />
            <Drawer title="Create feature" placement="right" onClose={onClose} visible={visible}>
              <CreateFeature />
            </Drawer>
          </Content>
        </Layout>
      </Layout>    
    );
  }  
}

export default App;
