import { Service } from './Service';

export const featureEnabled = async (key: string, deefault : boolean) : Promise<boolean> => {
    try {
        const cache = await caches.open('cloudflag');
        const cacheHeaderName = 'x-cloudflag-ttl';
        const cacheKey = `/${key}.json`;
        const minutesToCache = 0.25;
        const timerKey = `Fetched feature ${key} in`;

        console.time(timerKey);

        // Check if feature exists and hasn't expired
        const cachedFeature = await cache.match(cacheKey);
        if(cachedFeature != null){
            console.debug(`Found ${key} feature in cache.`);
            const ttl = cachedFeature.headers.get(cacheHeaderName);
            if(ttl && Date.now() < parseInt(ttl)){
                const ttlInt = parseInt(ttl);
                const currentTime = Date.now();
                if(currentTime < ttlInt){
                    console.debug(`Feature expiring in ${((ttlInt - currentTime) / 60000).toFixed(2)} minutes`)
                    const value = await cachedFeature.text();
                    console.debug(`Returning ${value} from cached feature.`);
                    console.timeEnd(timerKey);
                    return value === 'true';
                }                
            }
        }

        // Get feature, append ttl header, and populate cache
        console.debug('Refetching feature as it either does not exist or is expired.');
        const feature = await Service.getFeature(key);
        const time = Date.now() + minutesToCache * 60000;
        console.debug(`Feature expiring in ${minutesToCache} minutes`)

        if(feature){
            // Clone response to mutate headers
            let initHeaders = {};
            initHeaders[cacheHeaderName] = time.toString();
            let init = {
                status: feature.status,
                statusText: feature.statusText,
                headers: initHeaders,
            }
            
            feature.headers.forEach(function (val, key) {
                init.headers[key] = val
            })

            const value = await feature.text();
            cache.put(cacheKey, new Response(value, init));
            console.debug(`Returning ${value} from fetched feature.`);
            console.timeEnd(timerKey);             

            return value === 'true';
        }
        
        console.debug(`Returning ${deefault} from default value.`);
        console.timeEnd(timerKey);
        return deefault;

    } catch {}

    return deefault;
}