import { useState, useEffect } from 'react';
import { Switch, List } from 'antd';
import { Service } from './Service'
import { Feature } from './Feature'

const FeatureList = () => {
    const [features, setFeatures] = useState<Feature[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        async function getFeatures(){
            if(features === null){
                const ftrs = await Service.getFeatures();
                setFeatures(ftrs);
                setLoading(false);
            }            
        }
        
        getFeatures();
    
    }, [features]);

    const toggleFeature = async (item) => {
        if(features == null)
            return;

        let ftrs = features.map<Feature>(feature => {
            if(feature.key === item.key)
                feature.value = !item.value;

            return feature;
        });

        setFeatures(ftrs);

        await Service.setFeature(item.key, item.value);
    };

    return (
        <List
            style={{ backgroundColor: '#fff' }}
            itemLayout="vertical"
            dataSource={features ?? []}
            bordered
            loading={loading}
            size="large"
            header={<h1>Features</h1>}
            renderItem={item => (
                <List.Item
                key={item.key}
                extra={<Switch onChange={async () => await toggleFeature(item)} checked={item.value} checkedChildren="On" unCheckedChildren="Off" />}>
                    <List.Item.Meta
                    title={item.name}
                    description={`
                        Last toggled on ${new Date(item.updated * 1000).toDateString()}
                        `}
                    />
                </List.Item>
            )}
        />
    )
}

export default FeatureList;