import { ApiConfig } from './../../ApiConfig';
import { Feature } from './Feature'

export const Service = {
    getFeatures: async () : Promise<Feature[]> => {
        const response = await fetch(`/api/features/get/all?token=${ApiConfig.apiKey}`);
        return await response.json();
    },

    setFeature: async (key: string, value: boolean) : Promise<Response> => {
        let request = { value: value };
        const response = await fetch(`/api/features/set/${key}?token=${ApiConfig.apiKey}`,
        {
            method: 'POST',
            body: JSON.stringify(request)
        });

        return response;
    }
}