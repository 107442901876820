import { CreateRequest } from './CreateFeatureRequest'
import { ApiConfig } from './../../ApiConfig';

export const Service = {
    createFeature: async (feature: CreateRequest) : Promise<Response> => {
        const response = await fetch(`/api/features/create`,
        {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'x-api-key': ApiConfig.apiKey
            },
            body: JSON.stringify(feature)
        });

        return response;
    }
}